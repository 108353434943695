@font-face {
  font-family: "Amazon";
  src: url("./assets/fonts/Amazon-Ember-Medium.ttf");
}

@font-face {
  font-family: "Amazon-light";
  src: url("./assets/fonts/AmazonEmber_Lt.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Amazon", Helvetica, sans-serif;
}

body {
  overflow-x: hidden;
}

:root {
  --amazon-button: #f0c14b;
  --yellow: #f7ca00;
  --darkblue: #232f3e;
  --lightgray: #f3f3f3;
  --gray: #616060;
  --blue: #0066c0;
  --orange: #c7511f;
  --black: #0f1111;
  --green: #007600;
  --success-green: #067d62;
  --red: #c40000;
}

button {
  font-family: "Amazon-light";
  font-weight: 600;
  cursor: pointer;
  border: 1px solid white;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 3px;
  outline-color: var(--orange);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 183, 0, 0.293),
    var(--amazon-button)
  );
}

button:hover {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 183, 0, 0.5),
    var(--amazon-button)
  );
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

a,
a:visited,
a:focus,
a:active {
  color: white;
  text-decoration: none;
  width: fit-content;
  cursor: pointer;
}
ul,
li {
  list-style: none;
}

select {
  outline: orange;
}

.nav--hover {
  padding: 0.4rem;
  height: 49px;
  border: 1px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  align-items: center;
  margin: 0 1px;
}

.nav--hover:hover {
  border-color: white;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 5px solid gainsboro;
  border-top: 5px solid rgb(81, 171, 245);
  animation: Loader-spin 1s infinite;
}

/* Hover Transitions */

@keyframes fadeAway {
  0% {
    transform: translateY(-100%);
    opacity: 1;
    z-index: -1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    z-index: -1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Loader Div */

@keyframes Loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.colored-link {
  /* color: var(--blue) !important; */
  color: #007185 !important;
}
.colored-link:hover {
  color: var(--orange) !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through;
}

.main-container {
  font-family: "Amazon-light";
  min-height: 70vh;
  max-width: 1500px;
}

.padded-container {
  width: 85%;
  margin: auto;
  padding: 1rem 0;
}

@media (min-width: 1440px) {
  .main-container {
    margin: auto;
  }
}

@media (max-width: 1024px) {
  .padded-container {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .padded-container {
    width: 95%;
  }
}

@keyframes accordian {
  0% {
    max-height: 60px;
  }
  50% {
    max-height: 50%;
  }
  100% {
    max-height: 100%;
  }
}
